import React from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { LayoutProvider, Wrapper } from "../../components/layout";
import { FAQBanner } from "../../components/sections";
import { useViewPortSize } from "../../hooks";
import { stackStyled } from "../../theme";
import { StackOnBreadcrumbs } from "../../atoms";

//Query
const query = graphql`
  query {
    strapiFaq {
      title
      faqSection {
        id
        title
        iconButton {
          url
        }
        iconActive {
          url
        }
        faqQuestion {
          id
          question
          answerBody
        }
      }
    }
  }
`;

//Interfaces
export interface IFAQQuestion {
  id: number;
  question: string;
  answerBody: string;
}

export interface IFAQSection {
  id: string;
  title: string;
  iconButton: {
    url: string;
  };
  iconActive: {
    url: string;
  };
  faqQuestion: Array<IFAQQuestion>;
}

export interface IFAQ {
  strapiFaq: {
    id: number;
    title: string;
    faqSection: Array<IFAQSection>;
  };
}

//Styled Compontents

const ContainerBreadCrumbs = stackStyled(
  "div",
  (props: { isMobile: boolean, isTablet: boolean }) => ({
    position: "absolute",
    top: props.isMobile ? "10px" : "30px",
    left: props.isTablet ? '10px' : props.isMobile ? '8px' : "75px",
    width: "380px",
    backgroundColor: "transparent",
  })
);

const MainWrapper = stackStyled(Wrapper, () => ({
  display: "flex",
  justifyContent: "center",
  marginLeft: "auto",
  marginRight: "auto",
  overflowX: "hidden",
  textAlign: "center",
  backgroundColor: "none",
}));

const FAQs: React.FC<PageProps> = (props: PageProps) => {
  //Hooks
  const data = useStaticQuery<IFAQ>(query);
  const [isMobile, isTablet] = useViewPortSize();

  let addTitle = props.location.search
    ? props.location.search.split("=")[1].split("+").join(" ")
    : "";

  return (
    <LayoutProvider
      seo
      locationSearch={props.location.search}
      titleExt={addTitle}
    >
      <MainWrapper id="top" noScroll>
        <ContainerBreadCrumbs isMobile={isMobile} isTablet={isTablet}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Customer service', href: '/customer-service' },
              { title: 'FAQs', href: '/customer-service/faqs' },
            ]}
          />
        </ContainerBreadCrumbs>
        <FAQBanner data={data} />
      </MainWrapper>
    </LayoutProvider>
  );
};

export default FAQs;
